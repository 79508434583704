<template>
  <!--begin::Body-->
  <div class="card-body mb-2 py-3">
    <!--begin::Table container-->
    <div class="table-responsive fixTableHead">
      <!--begin::Table-->
      <table
        class="table able-condensed small table-row-gray-500 gy-3 gs-5 mb-0"
      >
        <!--begin::Table head-->
        <thead id="table-head">
          <tr class="fw-bolder text-muted">
            <th class="text-dark fs-7 fw-bolder text-start">Referência</th>
            <th class="text-dark fs-7 fw-bolder text-start">Abreviatura</th>
            <th class="text-dark fs-7 fw-bolder text-start">Descrição</th>
            <th class="text-dark fs-7 fw-bolder">Tipo Stock</th>
            <th class="text-dark fs-7 fw-bolder">Quantidade</th>
            <th class="text-dark fs-7 fw-bolder">Imagem</th>
            <th class="text-end">
              <a
                @click="resetNewOrder"
                class="btn btn-sm btn-clean btn-icon mx-1"
                style="height: 1rem; width: 2.4rem"
                title="Apagar Nova Encomenda"
                v-if="list.length > 0"
              >
                <i class="fas fa-trash text-dark"></i>
              </a>
            </th>
          </tr>
        </thead>
        <!--end::Table head-->

        <!--begin::Table body-->
        <transition-group name="list" tag="tbody">
            <tr v-for="(item, index) in list" :key="item" class="clickable-row border-bot">
              <td @click="editLine(index)" class="fs-6 align-middle text-start">
                {{ item.refe }}
              </td>
              <td @click="editLine(index)" class="fs-6 align-middle text-start">
                {{ item.abv }}
              </td>
              <td @click="editLine(index)" class="fs-6 align-middle text-start">
                {{ item.design }}
              </td>
              <td
                @click="editLine(index)"
                class="fs-6 align-middle text-center"
              >
                {{ item.stk ? item.stk : "" }}
              </td>
              <td @click="editLine(index)" class="fs-6 align-middle">
                {{ item.qtt }}
              </td>
              <td class="fs-6 align-middle">
                <div><DisplayImage :item="item.refe"></DisplayImage></div>
              </td>
              <td
                class="align-middle text-end pt-1 pb-1"
                style="min-width: 120px"
              >
                <button
                  v-if="techDoc(item.refe)"
                  @click="downloadPdf(techDoc(item.refe))"
                  class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm mx-1 my-1"
                  style="
                    background-color: white;
                    border: 1px solid #d1d3e2;
                    height: 2.4rem;
                    width: 2.4rem;
                  "
                  id="btn-pdf-download"
                  :download="techDoc(item.refe)"
                  :href="techDoc(item.refe)"
                  title="Abrir/descarregar ficha técnica"
                >
                  <i class="fas fa-file-pdf"></i>
                  <!-- <span class="svg-icon svg-icon-3 svg-icon-dark">
                    <inline-svg src="media/icons/duotune/files/fil009.svg" />
                  </span> -->
                </button>
                <button
                  type="button"
                  class="btn btn-icon btn-sm btn-svg-remove mx-1 my-1"
                  style="
                    color: white !important;
                    background-color: white;
                    border: 0.1rem solid #d1d3e0;
                    height: 2.4rem;
                    width: 2.4rem;
                  "
                  @click="removeLine(index)"
                  title="Remover"
                >
                  <div class="symbol-label">
                    <span class="svg-icon-2 svg-icon mb-2">
                      <inline-svg
                        src="media/icons/duotune/csw_custom/arr061.svg"
                      />
                    </span>
                  </div>
                </button>
              </td>
            </tr>
        </transition-group>
        <!--end::Table body-->
      </table>
      <!--end::Table-->
    </div>
    <!--end::Table container-->
  </div>
  <!--begin::Body-->
</template>

<script>
import store from "@/store";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import DisplayImage from "@/components/Orders/DisplayImage.vue";

export default {
  name: "NewOrderTable",
  components: { DisplayImage },
  props: {
    list: Array,
  },
  computed: {},
  emits: ["editLine", "removeLine"],
  methods: {
    editLine(index) {
      this.$emit("editLine", index);
    },
    removeLine(index) {
      this.$emit("removeLine", index);
    },
    resetNewOrder() {
      Swal.fire({
        title: "Apagar Nova Encomenda",
        text: "Tem a certeza que quer apagar a nova encomenda?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Apagar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$emit("resetNewOrder");
        }
      });
    },
    techDoc(ref) {
      return store.getters.getProductDocumentByRef(ref);
    },
    downloadPdf(docId) {
      //call api, get file in b64 format, and download it
      ApiService.get("Utils/document/" + docId).then((response) => {
        let file = response.data.Data.docBase64;
        let fileName = response.data.Data.docName;
        const parts = fileName.split(".");
        let fileExtension = parts[parts.length - 1];
        let mimeType = "application/pdf";
        if (fileExtension === "xml") {
          mimeType = "application/xml";
        } else if (fileExtension === "txt") {
          mimeType = "text/plain";
        }

        let byteCharacters = atob(file);
        let byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        let blob = new Blob([byteArray], { type: mimeType });

        // Create a URL for the Blob
        let fileURL = URL.createObjectURL(blob);

        // Open the file in a new tab
        let newTab = window.open(fileURL, "_blank");
        if (newTab) {
          newTab.document.title = fileName;
        } else {
          // Opening the new tab was blocked by the browser
          console.error("Opening the file was blocked by the browser.");
          window.location.href = fileURL;
        }
      });
    },
  },
};
</script>

<style scoped>
.fixTableHead {
  overflow-y: auto;
}
.fixTableHead thead th {
  position: sticky;
  top: 10;
}
table {
  border-collapse: collapse;
  width: 100%;
}

th {
  color: black !important;
  text-align: -webkit-center;
}

td {
  text-align: -webkit-center;
  border-bottom: 2px solid white;
}

tbody tr:nth-child(even) {
  background-color: #eef9ff;
}

tbody tr:nth-child(odd) {
  background-color: #ddf3ff;
}

#table-head {
  background-color: #fff200;
}

#statusBadge {
  border-radius: 15px;

  text-align: center;
}

.btn-color {
  background-color: #bdb4b6ff;
  color: black;
}

.btn-color:hover {
  background-color: #adadad;
}

.btn-danger {
  background-color: #e30b17 !important;
  color: white;
}

.btn-svg-remove:hover {
  background-color: #071422 !important;
  opacity: 0.8 !important;
  fill: #ffffff !important;
  stroke: #ffffff !important;
  enable-background: new;
}

.clickable-row {
  cursor: pointer;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: scaleY(0);
}

/* .list-leave-active {
  position: relative;
} */
</style>
