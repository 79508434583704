<template>
  <div @click="openImage" class="d-flex justify-content-center">
    <img v-if="imgUri" :src="imgUri" class="card-img rounded fixed-height" :alt="item" />
  </div>
  <!-- Open pdf in a tab
    <a href="https://www.africau.edu/images/default/sample.pdf" target="_blank">Open PDF</a>
  -->
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  name: "DisplayImage",
  data() {
    return {
      imgUrl: "",
    };
  },
  props: {
    item: Object,
  },
  computed: {
    imgUri() {
      if (this.imgUrl) {
        return this.imgUrl;
      } else {
        return ""; //require("@/assets/products/default-img.gif");
      }
    },
  },
  watch: {
    item: {
      handler: function () {
        this.imgUrl = "";
        this.image();
      },
      deep: true,
    },
  },
  methods: {
    image() {
      let imageUrl = `https://portal.ferragensdomarques.pt/store/${this.item}.png`;
      let image = new Image();
      image.src = imageUrl;
      //wait for image to load
      image.onload = () => {
        this.imgUrl = imageUrl;
      };
    },
    openImage() {
      Swal.fire({
        showCancelButton: false,
        showConfirmButton: false,
        imageUrl: this.imgUrl,
        imageAlt: this.item,
        showCloseButton: true,
        padding: '1.5rem 0 0 0',
      });
      /* 
      // Open pdf in modal -- beta
      Swal.fire({
        showCancelButton: false,
        showConfirmButton: false,
        width: '100%',
        height: '100%',
        html: `
          <object
            style="width:100%;height:100rem;max-height:100rem;"
            data="https://www.africau.edu/images/default/sample.pdf"
            type="application/pdf"
          ></object>
        `,        
        showCloseButton: true,
        padding: '0',
      }); */
    },
  },
  mounted() {
    this.image();
  },
};
</script>

<style scoped>
.fixed-height {
  display: block;
  height: 4rem; /* set the fixed height of the image */
  width: auto; /* make the width adaptable */
  max-width: 20rem;
}
</style>
